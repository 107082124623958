<script>
    import Layout from "@/views/layouts/static/main";
    import PageHeader from "@/components/static/page-header";
    import appConfig from "@/app.config";
    import {mapState} from "vuex";
    import {locationMethods} from  "@/state/helpers";

    import Create from "@/views/pages/admin/locations/components/Create";
    import Address from "@/views/pages/admin/locations/components/Address";
    import Availability from "@/views/pages/admin/locations/components/Availability";
    import Schedule from "@/views/pages/admin/locations/components/Schedule";
    import ShiftBonus from "@/views/pages/admin/locations/components/ShiftBonus";
    import PastEmployeeHistory from "@/views/pages/admin/locations/components/PastEmployeeHistory";
    
    export default {
        page: {
            title: "Edit Location",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,Create,Address,
            // Availability,Schedule,ShiftBonus,PastEmployeeHistory
        },

        mounted(){
        this.fetchLocation();
        },

        methods: {
            ...locationMethods,
           fetchLocation() {
                this.$axios.get("/restaurant/location/"+this.$route.params.location_id).then((response) => {
                let data = response.data.payload;
                this.$store.state.locationModule.location=data
                }).catch(error => {
                this.handleErrorResponse(error.response, "error");
                }).finally(function () {
                });
            },

            updateLocation(){
                    let self = this;
                if (self.processing) {
                return;
                }
                    self.location.restaurant_id=self.currentUser.restaurant.id;
                    self.location.time_zone_id=self.location.time_zone.id;
                    // self.location.areas_id=self.getIdFromArray(self.location.areas)
                    // self.location.area_roles_id=self.getIdFromArray(self.location.area_roles);
                
                self.processing = true;
                
                self.$axios.post('/restaurant/location/store', {
                location:self.location
                    
                })
                .then(response => {
                    let data = response.data;

                    self.triggerSwal('Location updated Successfully', "success");
                    self.resetState();
                    
                    self.$router.push("/locations");
                  
                })
                .catch(error => {
                    console.error(error)
                    self.handleErrorResponse(error.response, "error");
                    
                })
                .finally(function () {
                    self.processing = false;
                });
            },
        //       getIdFromArray(arrray){
        //       let id_array= arrray.map((item) => {
        //           return item.id
                  
        //         });
        //         return id_array;
        //    },
        },

           computed: {
             ...mapState({
            location: state => state.locationModule.location
        }),

    },
        data() {
            return {
                 title: "Edit Location",
                items: [
                    {
                        text: "Locations",
                    },
                    {
                        text: "Create",
                        active: true,
                    },
                ],
                processing:false,
                currentTabComponent: 'Create',
                tabs: [
                        {
                            id:1,
                            desc: 'Location Info',
                            component_name: 'Create',
                            icon: 'uil uil-location-arrow'
                        },
                        {
                            id:2,
                            desc: 'Address',
                            component_name: 'Address',
                            icon: 'uil uil-location-point'
                        },
                        // {
                        //     id: 3,
                        //     desc: 'Availability',
                        //     component_name: 'Availability',
                        //     icon: 'fa-money-bill-wave-alt',
                        // },
                        // {
                        //     id: 4,
                        //     desc: 'Schedule',
                        //     component_name: 'Schedule',
                        //     icon: 'fa-file',
                        // },
                        // {
                        //     id: 5,
                        //     desc: 'Shift Bonus',
                        //     component_name: 'ShiftBonus',
                        //     icon: 'fa-file',
                        // },
                        // {
                        //     id: 6,
                        //     desc: 'Past Employees History',
                        //     component_name: 'PastEmployeeHistory',
                        //     icon: 'fa-file',
                        // },

                ],
            };
        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :items="items" :title="title"/>
        <div class="row">
        <div class="col-12 col-md mb-3 ">
            <ul class="nav nav-pills tabs-pills" role="tablist">
                <li class="nav-item" v-for="(tab,index) in tabs" :key="index">
                    <a class="nav-link d-flex align-items-center"
                       :class="{active: currentTabComponent === tab.component_name}"
                       :id="tab.id"
                       @click="currentTabComponent = tab.component_name"
                       data-toggle="tab" href="#loan-file" aria-controls="loan-file" role="tab"
                       aria-selected="true">
                        <i class="me-2 fas" :class="tab.icon"></i><span class="d-none d-sm-block">{{ tab.desc }}</span>
                    </a>
                </li>
            </ul>
        </div>
        </div>
        <div class="card">
            <div class="card-body under-tabs">
                <div class="row">
                    <div class="col-12">
                        <div class="tab-content">
                    <component :is="currentTabComponent"></component>
                        </div>
                    </div>
                </div>
            </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-12">
                    <div class="float-right">
                        <submit-button @clicked="updateLocation" :processing="processing"
                                       text="Update Location">
                        </submit-button>
                    </div>
                </div>
            </div>

        </div>
        </div>
    </Layout>
</template>
